.toggleButton {
  position: relative;
  display: block;
  border-radius: 500px;
  user-select: none;
  height: var(--nav-button-height);
  width: calc(1.5*var(--nav-button-height));
  line-height: 1;
  overflow: hidden;
  border: 1px solid rgb(var(--foreground));
  background-color: rgb(var(--background));
}

@media not (hover: none) {
  .toggleButton {
    transition: border-color 0.3s ease;
  }

  .toggleButton:hover {
    border-color: transparent;
  }
}

.toggleButton:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(calc(2px + var(--nav-button-height) * 0.5), -50%);
  width: calc(var(--nav-button-height) - 6px);
  height: calc(var(--nav-button-height) - 6px);
  border-radius: 50%;
  z-index: 1;
  background-color: rgb(var(--foreground));
  transition: transform 0.5s ease, filter 0.5s ease;
  filter: blur(4px);
  pointer-events: none;
}

.toggleButton[data-focus-mode="true"]::after {
  transform: translate(2px, -50%);
  filter: blur(0px);
}
