.dot {
  position: fixed;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 12px;
  pointer-events: none;
  mix-blend-mode: difference;
  z-index: 100;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center;
}

.text {
  width: 2px;
  height: 20px;
  border-radius: 0;
}

.altDot {
  position: fixed;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  pointer-events: none;
  mix-blend-mode: difference;
  z-index: 1000;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center;
}
