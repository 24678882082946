:root {
  --js-enabled: 1;
  --js-hidden: none;

  /* constants */
  --PI: 3.14159265358979;
  --gr: 1.618;
  --grSq: calc(var(--gr) * var(--gr));

  /* viewport information */
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
  --tah: env(titlebar-area-height);

  /* this viewport height variable is overwritten by next-real-viewport if js is available */
  --vh: 1%;

  /* default cursor value for non-js devices. will be updated to 'none' if javascript is enabled */
  --cursor: none;

  /* color pallete */
  --black: 51 51 51; /* rgb(51 51 51) */
  --navy: 46 61 78; /* rgb(46 61 78) */
  --navy-dark: 39 52 66; /* rgb(39 52 66) */
  --blue: 51 51 204; /* rgb(51 51 204) */
  --white: 204 204 204; /* rgb(204 204 204) */
  --offwhite: 204 189 172; /* rgb(209 194 177) */
  --offwhite-dark: 196 180 163; /* rgb(196 180 163) */

  --light-inner: var(--offwhite);
  --dark-inner: var(--navy-dark);
  --light: var(--offwhite-dark);
  --dark: var(--navy);
  /* --light: var(--white); */
  /* --dark: var(--navy); */

  /* default theme settings */
  --foreground: var(--light);
  --background: var(--dark);
  --background-inner: var(--dark-inner);
}

html:root {

  /* layout variables */
  --frame-margin-x: 3vmin;
  --frame-margin-y: 1vh;
  --frame-padding: calc(var(--frame-margin-x) * var(--gr));
  --double-frame-padding: calc(var(--frame-padding) * 2);
  
  --scrollbar-width: calc(var(--frame-margin-x) / var(--gr));
  
  --nav-button-height: 24px;
  --nav-height: calc(var(--nav-button-height) + 2 * var(--frame-margin-y));

  --frame-width: calc(100vh*var(--gr));
  --frame-max-width: 100%;
  --frame-height: calc(100vh - 2*var(--nav-height));
  --frame-height-perc: calc(100% - 2*var(--nav-height));

  --screen-height-responsive: calc(100 * var(--vh) - env(safe-area-inset-bottom) - env(safe-area-inset-top));
}

/* wide screen */
@media (min-aspect-ratio: 1/1) {
  html:root {
    --frame-margin-x: 3vmin;
    --frame-margin-y: 1vh;
    --frame-padding: 2.5rem;
  }
}

@media (prefers-color-scheme: dark) {
  [data-theme='u'] {
    --foreground: var(--light);
    --background: var(--dark);
    --background-inner: var(--dark-inner);
  } 
}
@media (prefers-color-scheme: light) {
  [data-theme='u'] {
    --foreground: var(--dark);
    --background: var(--light);
    --background-inner: var(--light-inner);
  }
}

[data-theme='l'] {
  --foreground: var(--dark);
  --background: var(--light);
  --background-inner: var(--light-inner);
}
[data-theme='d'] {
  --foreground: var(--light);
  --background: var(--dark);
  --background-inner: var(--dark-inner);
}
