.outlineSvg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  pointer-events: none;
  width: calc(100% - var(--frame-margin-x) * 2);
  max-width: calc(100vh*var(--gr));
  height: calc(100% - var(--nav-height) * 2);
  overflow: visible;
}

.outlinePath {
  stroke: rgb(var(--foreground));
  stroke-width: 1px;
}

.maskSvgContainer {
  @apply space-y-[-1px];
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maskSvgContainer::before {
  content: '';
  position: relative;
  width: 100%;
  height: var(--nav-height);
  background-color: rgb(var(--background));
  pointer-events: all;
  flex-shrink: 0;
}
.maskSvgContainer::after {
  content: '';
  position: relative;
  width: 100%;
  margin-top: -1px;
  height: calc(1px + var(--nav-height));
  background-color: rgb(var(--background));
  pointer-events: all;
  flex-shrink: 0;
}

.maskSvg {
  width: var(--frame-width);
  max-width: var(--frame-max-width);
  height: calc(100% - 2 * var(--nav-height) + 1px);
  overflow: hidden;
  flex-shrink: 0;
}

.shadowPath {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1rem;
  z-index: 0;
}

.maskPath {
  fill: rgb(var(--background));
  z-index: 1;
  pointer-events: fill;
}

.detectionSvg {
  position: fixed;
  top: var(--nav-height);
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  pointer-events: none;
  width: calc(var(--frame-width));
  max-width: calc(var(--frame-max-width));
  height: calc(100% - 2 * var(--nav-height));
  overflow: hidden;
}

.detectionPath {
  fill: transparent;
  z-index: 0;
  pointer-events: fill;
}
