@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(-50% - env(safe-area-inset-bottom)));
  }

  .fixed-center {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(-50% - env(safe-area-inset-bottom)));
  }

  .fix-blend-mode-safari {
    transform: translate3d(0,0,0);
  }
  
  .animate-theme {
    transition:
      background-color 1s ease,
      border-color 1s ease,
      stroke 1s ease,
      fill 1s ease;
  }
  
  .animate-text-theme {
    transition: color 1s ease,
      background-color 1s ease,
      border-color 1s ease,
      stroke 1s ease,
      fill 1s ease;
  }
  
  .image-pixelated {
    image-rendering: pixelated
  }
}

@import "./constants.css";

html, body, a, button {
  cursor: var(--cursor);
}

html {
  position: fixed;
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  /* pointer-events: all;/ */
  position: fixed;
  font-weight: 300;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  color: rgb(var(--foreground));
}

/* body, main {
  transition-property: background;
  transition-duration: 1000ms;
  transition-timing-function: ease;
} */

main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  background-color: rgb(var(--background));
  z-index: 0;
  padding: 0 var(--frame-margin-x);
  pointer-events: all;
  display: block;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
}

body *::selection {
  background-color: rgb(var(--foreground));
  color: rgb(var(--background));
  z-index: 0;
}

main::-webkit-scrollbar-corner {
  display: none;
}

[data-remove-with-js="true"] {
  display: var(--js-hidden);
}
